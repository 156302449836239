import React from 'react'
import {Route,Routes} from "react-router-dom"
import App from '../App'
import Subscriber from './Subscriber'

function Home() {
  return (<>
  <Routes>
    <Route exact path="/" element={<App/>} />
    <Route path="/subscriber" element={<Subscriber/>} />
  </Routes>
  
  
  
  </>

  )
}

export default Home