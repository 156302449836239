import React, { useState } from "react";
import "./HomeTopSec.css";
import CityLeft from "../../assets/A_City_L.png";
import CityRight from "../../assets/A_City_R.png";
import CityBack from "../../assets/A_City_Back.png";
import CityTraffic from "../../assets/A_City_Traffic.png";
import ArrowSelect from "../../assets/Arrow_Select.png";
import HeadRbtz from "../../assets/Head_RbtZ.png";
import Rbtz from "../../assets/A_RbtZ.png";
import Logo from "../../assets/Logo.png";
import B_City_L_02 from "../../assets/B_City_L_02.png";
import B_City_R_02 from "../../assets/B_City_R_02.png";
import Group11 from "../../assets/Group 11.png";
import BCityBack from "../../assets/B_City_Back.png";
import CityLeft1 from "../../assets/C_City_L_01.png";
import CityLeft2 from "../../assets/C_City_L_02.png";
import CityRight2 from "../../assets/C_City_R_01 (2).png";
import CityRight1 from "../../assets/C_City_R_02 (1).png";
import B_City_L_01 from "../../assets/B_City_L_01.png";
import C_City_Back from "../../assets/C_City_Back.png";
import B_Undertext from "../../assets/B_Undertext.png";
// import Compositelayer3 from "../../assets/Compositelayer3.png";
import BottomBuildingL from "../../assets/Bulding_L.png";
import BottomBuildingR from "../../assets/Bulding_R.png";
import RBTZ_Bopttom from "../../assets/RBTZ_Bopttom.png";
import Rbtz_bot from "../../assets/rbtz_bot.png";
import FaqLine from "../../assets/faqline.png";
import QuestionAnswer from "./QuestionAnswer";
import Line_bottom from "../../assets/Line_bottom.png";
import TopTable from "../../assets/EditedTextCopy-removebg-preview.png";
import Commands from "../../assets/commands.png";
import Timestamp from "../../assets/timestamp.png";
import C_Line_bottom from "../../assets/C_Line_bottom.png";
import MobileRbtzDetail from "../MobileRbtzDetail/MobileRbtzDetail";
import Fourthsec from "../MobileRbtzDetail/Fourthsec";
import INput from "../MobileView/INput";
import { AiOutlineTwitter } from "react-icons/ai"
import { BsDiscord } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai"
import ins from "../../assets/ins.png"
import dis from "../../assets/dis.png"
import twi from "../../assets/twi.png"

function HomeTopSec() {

  const [show, setShow] = useState(false)
  const handleDisplay = (e) => {
    console.log("You click me")
    console.log(e.target.id)
    e.target.style.display = "none";
    let elem = document.getElementById("ques_" + e.target.id);
    elem.style.display = "block";
  }


  const handleDisplay2 = (e) => {
    console.log("You click 2")
    console.log(e.target.id)
    e.target.style.display = "none";
    let elem = document.getElementById(e.target.id.split("_")[1]);
    elem.style.display = "block";
  }

  return (
    <>
      <section className="home-sec-top p-0 m-0">
        <div style={{ zIndex: 9 }} className="home-sec-top-div  p-0 m-0 d-flex">
          <img className="building_left" src={CityLeft} alt="City left" />
          <div className="home_div_top d-flex justify-content-end align-items-end">
            <div className="mb-2 home_div_top_child">
              <img className="rbtz" src={Rbtz} alt="" />
              <img className="logo-img" src={Logo} alt="" />
              <div className="d-flex my-3 ps-3 justify-content-between align-items-center">
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  <img src={ArrowSelect} alt="" />
                  <p className="home_top_arrow">SHOP</p>
                </div>
                <div className="d-flex gap-2 justify-content-center align-items-center">
                  <img src={ArrowSelect} alt="" />
                  <p className="home_top_arrow">
                    Your
                    <img className="mx-2 head_rbtz" src={HeadRbtz} alt="" />
                  </p>
                </div>
                <span className="block_address">0x...dc25</span>
              </div>
            </div>
          </div>
          <a href="https://opensea.io/" >
            <button className="viewOnSea home_div_top-btn">
              View on OpenSea
            </button>
          </a>
          <img className="building_back" src={CityBack} alt="city back" />
          <img className="building_right" src={CityRight} alt="City left" />
          <img src={CityTraffic} alt="CityTraffic" className="CityTraffic" />
        </div>
      </section>
      <div className="web_input" >
        <INput />
      </div>

      <section className="second_section">
        <div className="second-section-div p-0 container-fluid">
          <img
            className="img_two"
            style={{ zIndex: 9 }}
            src={B_City_L_02}
            alt=""
          />
          <div style={{ zIndex: 10 }} className="second_sec_image">
            <MobileRbtzDetail />
          </div>
          <img
            className="img_one"
            style={{ zIndex: 9 }}
            src={B_City_R_02}
            alt=""
          />
          <img
            style={{ zIndex: 8 }}
            src={BCityBack}
            alt=""
            className="b_city_back"
          />
        </div>
      </section>
      <section className="third_section">
        <div className="third_section_div p-0 d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column third-section-div1 ">
            <img
              src={B_City_L_01}
              alt=""
              style={{ zIndex: 9 }}
              className="B_City_L_01"
            />
            <img
              src={CityLeft2}
              style={{ zIndex: 9 }}
              alt=""
              className="city_left_02 third-section-1img2"
            />
            <img
              src={CityLeft1}
              alt=""
              style={{ zIndex: 9 }}
              className="city_left_01 third-section-1img1"
            />
          </div>
          <div className="third-section-center" style={{ width: "100%", zIndex: "9999999999999999" }}>
            <Fourthsec />
            <div className=" d-flex justify-content-center">
              <div className="sec3_parentdiv" style={{ zIndex: "99999999999" }}>
                <div className="section3_accordions">
                  <p className="map_text">ROAD MAP</p>
                  <div className="accordion1">
                    <p className="q1">Q_20 2022</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button acc1_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                         > Giveaway
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body body1">

                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="q1">Q_20 2022</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingtwo">
                          <button class="accordion-button acc1_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                          > First Rbtz release
                          </button>
                        </h2>
                        <div id="collapsetwo" class="accordion-collapse collapse " aria-labelledby="headingtwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body body1">

                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="q1">Q_20 2022</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingthree">
                          <button class="accordion-button acc1_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded="true" aria-controls="collapsethree">
                          > Release of illustrations
                          </button>
                        </h2>
                        <div id="collapsethree" class="accordion-collapse collapse " aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                          <div class="accordion-body body1">

                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="q1">Q_20 2022</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingfour">
                          <button class="accordion-button acc1_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="true" aria-controls="collapsefour">
                            <div>
                              <p className="text"> >  package with multiple Rbtz</p>
                              <p className="text text1 ms-4">-[Assembly line theme]</p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapsefour" class="accordion-collapse collapse " aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                          <div class="accordion-body body1">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion2">
                    <p className="q2">Q_20 2023</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingfive">
                          <button class="accordion-button acc3_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                            <div>
                              <p className="text">  > Create your own custom  Rbtz</p>
                              <p className="text text1 ms-4"> - Shop to customise your Rbtz</p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapsefive" class="accordion-collapse collapse " aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                          <div class="accordion-body body2">

                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="q2">Q_20 2023</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingsix">
                          <button class="accordion-button acc3_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="true" aria-controls="collapsesix">
                            <div>
                              <p className="text">> NFT Comic Book release </p>
                              <p className="text text1 ms-4">- Merchanize store launch</p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapsesix" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                          <div class="accordion-body body2">

                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="q2">Q_20 2022</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingseven">
                          <button class="accordion-button acc3_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="true" aria-controls="collapseseven">
                            <p className="text"> > Release of illustrations</p>
                          </button>
                        </h2>
                        <div id="collapseseven" class="accordion-collapse collapse " aria-labelledby="headingseven" data-bs-parent="#accordionExample">
                          <div class="accordion-body body2">

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="accordion3">
                    <p className="q3">Q_20 2022</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingeight">
                          <button class="accordion-button acc2_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="true" aria-controls="collapseeight">
                            <p className="text">  > Giveaway</p>
                          </button>
                        </h2>
                        <div id="collapseeight" class="accordion-collapse collapse " aria-labelledby="headingeight" data-bs-parent="#accordionExample">
                          <div class="accordion-body body3">

                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="q3">Q_20 2022</p>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingnine">
                          <button class="accordion-button acc2_bbtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="true" aria-controls="collapsenine">
                            <p className="text"> > First Rbtz release</p>
                          </button>
                        </h2>
                        <div id="collapsenine" class="accordion-collapse collapse " aria-labelledby="headingnine" data-bs-parent="#accordionExample">
                          <div class="accordion-body body3">

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column third-section-div2">
            <img
              src={CityRight1}
              alt=""
              style={{ zIndex: 9 }}
              className="city_right_01 third-section-2img1"
            />
            <img
              src={CityRight2}
              alt=""
              style={{ zIndex: 9 }}
              className="city_right_02 third-section-2img2"
            />
          </div>
          <img className="C_City_Back" src={C_City_Back} alt="" />
        </div>
      </section>

      <div className="container-fluid p-0">
        <div
          className="fourth_section  d-flex justify-content-center align-items-start"
          style={{ position: "relative" }}
        >
          <div className="text-center" style={{ width: "42%" }}>
            <img
              src={FaqLine}
              alt=""
              style={{ marginTop: "5rem", marginBottom: "2rem", zIndex: "10" }}
              className="mb-3 faq_img"
            />
            <div className="mt-5 ques_ans_parent">
              {QuestionAnswer?.map((elem, index) => {
                return (
                  <div>
                    <div className={`ques_ans ques_ans${index}`} >
                      <p className="ques text-start">{elem?.ques}</p>
                      <p className="ans text-start" id={index} onClick={handleDisplay} style={{ cursor: "pointer" }}> {elem?.ans.slice(0, 50) + "..."}</p>
                      <p className="ans text-start aaaa" id={"ques_" + index} onClick={handleDisplay2} style={{ cursor: "pointer" }}> {elem?.ans}</p>
                    </div>

                  </div>
                );
              })}

              <div className="ques_ans">
                <p className="ques text-start">How do I nft?</p>
                <p className="ans text-start" id="90" onClick={handleDisplay} style={{ cursor: "pointer" }}>
                  {" "}
                  &gt; New to NFTs? So are we but no worries, here are some
                  steps on what you need to do to get your ...
                </p>
                <p className="ans text-start aaaa" id="ques_90" onClick={handleDisplay2} style={{ cursor: "pointer" }}>
                  {" "}
                  &gt; New to NFTs? So are we but no worries, here are some
                  steps on what you need to do to get your RbTZ. <br /> <br />{" "}
                  Download the metamask.io extension for the Chrome/Brave
                  browser or app on mobile. This will allow you to make
                  purchases with Ethereum and can be found in the extensions
                  tab. If you are on mobile, you must use the Metamask App
                  Browser. <br /> <br /> You can purchase Ethereum through the
                  Metamask Wallet using Wyre or Send Ethereum from an exchange
                  like Coinbase. <br /> <br /> Click on Connect at the top of
                  the page and connect your Metamask. Once joined, you will be
                  able to purchase the NFTs in the mint section. You will be
                  prompted to sign your transaction. FYI, there will be a fee
                  associated with every transaction related to gas prices.{" "}
                  <br /> <br /> Once you have made your purchase, your RbTZ NFT
                  will be viewable in your wallet and on OpenSea.
                </p>
              </div>

              <img className="mt-5  mb-3 border_img" src={Line_bottom} alt="" />
            </div>
            <div style={{ position: "relative", zIndex: "10" }}>
              <p className="thanks_text">THANKS FOR STOPPING BY!!</p>
              <a href="https://opensea.io/" >
                <button className="viewOnSea-wos m-auto">View on OpenSea</button>
              </a>
              <div className="d-flex gap-3 justify-content-center mt-4">
                <a href="https://twitter.com">
                <img src={twi} className="img_social" />
                </a>
                <a href="https://discord.com">
                <img src={dis} className="img_social" /></a>
                <a href="https://www.instagram.com">
                <img src={ins} className="img_social" /></a>
              </div>
              <p className="follow">
                [ follow RBtZ on <span className="twitter">Twitter</span>{" "}
                <span className="instagram">Instagram</span>{" "}
                <span className="discord">Discord</span> ]
              </p>
              <div>
                <img className="TopTable" src={TopTable} alt="" />
              </div>
              <div>
                <img className="mt-5 nofi" src={Commands} alt="" />
              </div>
            </div>
          </div>
          <img
            style={{ zIndex: 9 }}
            className="BottomBuildingL"
            src={BottomBuildingL}
            alt="BottomBuildingL"
          />
          <img
            src={RBTZ_Bopttom}
            className="RBTZ_Bopttom"
            style={{ zIndex: 10 }}
            alt="RBTZ_Bopttom"
          />

          <img
            src={Rbtz_bot}
            className="Rbtz_bot"
            style={{ zIndex: 10 }}
            alt="RBTZ_Bopttom"
          />
          <img
            style={{ zIndex: 9 }}
            className="BottomBuildingR"
            src={BottomBuildingR}
            alt="BottomBuildingR"
          />

        </div>

      </div>
    </>
  );
}

export default HomeTopSec;
