import React, { useState } from 'react'
import layer from "../../assets/CompositeLayer2.png"
import robo from "../../assets/Group 2.png"
import robo1 from "../../assets/sec3r.png"

function Fourthsec() {
    const [shows, setShows] = useState(false)
    return (<>
        <div className='second_sec_image1'>
            <div className="MobileRbtzDetailParent">

                <img className="greenbox_img" src={layer} />
                <div className="sec2_mobil_text">
                    <p className={`${shows ? 'd-none' : 'd-flex'}`} onClick={() => setShows(!shows)} style={{ cursor: "pointer" }}>
                        &gt;  Rbtz are essential plaything in type II Human civilisation called Galleans.....
                    </p>
                    <p className={`bbbb ${shows ? 'd-flex' : 'd-none'}`} onClick={() => setShows(!shows)} style={{ cursor: "pointer" }} >
                        &gt; Rbtz are essential plaything in type II Human civilisation called Galleans.Galleans are living experiancing and Exploiting the universe through the  Exo-Sceleton, Surogates and sentient Robot frame called Rbtz!
                    </p>
                    <div className="border_div"></div>
                    <p className='text-end mt-1 bottom_t' >&gt; Central mainframe AGORA Center</p>

                </div>


                <div className="MobileRbtzDetailChild3 d-flex justify-content-center align-items-end">
                    <div className='fourth_sec_div '>
                        <img className='robo1' style={{ width: "100%" }} src={robo} />
                        <a href="https://opensea.io/">
                            <img className='robo2' style={{ width: "100%" }} src={robo1} />
                        </a>
                        <p className='fourth_sec_imgtext'>  &gt; Rbtz is a collection of dcybernetic chracters assign by steven, Filip and Ahmad and minted as NFTs.The are constructed by various scrap metal,circuitry, aloys arms and colors, Get your own unique Rbtz today!</p>
                        <p className='fourth_sec_imgtext2'>GET YOUR Rbtz</p>
                    </div>
                    <div>
                        <a href="https://opensea.io/">
                            <button className="viewOnSea forth_btn">
                                View on OpenSea
                            </button>
                        </a>
                    </div>


                </div>
            </div>
        </div>

    </>

    )
}

export default Fourthsec