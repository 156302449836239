import React, { useState } from 'react'
import { Base_URL } from '../../config'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function INput() {
    const [input, setinput] = useState("")



    function validateEmail(input) {
        const re = /\S+@\S+\.\S+/;
        return re.test(input);
    }

    const senddata = async () => {
        console.log("click")
        try {
            if (input.length === 0) {
                toast("Email must be required");
            }
            if (input.length > 0 && input.includes("@")) {
                
                let obj = {
                    email: input,
                }
                console.log(obj)
                const res = await axios.post("http://13.49.213.13:5000/mail/subscribe", obj)
                console.log(res,"postres")

                if (res.data === "User with that email is already subscribed") {
                    toast("User with that email is already subscribed");
                }
                else {
                    toast("Thanx for subScribing, you will be the first to know about our release and news");
                }
                console.log(res, "resss==>")
                setinput("")
            }
            else {
                
                toast("Email must be valid!");
            }

        } catch (error) {
            toast("Something went wrong");
        }
    }
    return (<>
        <ToastContainer />
        <div className='input_footer   d-flex justify-content-center pt-2 pb-4 '>
            <div className='text-center' style={{ width: "100%", maxWidth: "40%", position: "relative" }}>
                <input className='subscribe_email' name="email" type="email" required value={input} onChange={e => setinput(e.target.value)} placeholder='Enter your email' />
                <button className="connect_btn" style={{ width: "100%", maxWidth: "25%", zIndex: "999999", position: "absolute", right: "0px", top: "21px" }} onClick={senddata} >Subscribe</button>
                <p className='subscribe_text' style={{ fontSize: "40px", color: "white", marginTop: "10px", fontFamily: "KreKeTanStandard", lineHeight: "30px" }}>Subscribe to be  the first to know when NFTs are released and the latest updates!</p>
            </div>

        </div>

    </>

    )
}

export default INput