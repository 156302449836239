import React from 'react'
import HomeTopSec from './Components/HomeTopSec/HomeTopSec'
import MobileView from './Components/MobileView/MobileView'

function App() {
  return (<>
  <div className="desktop">
        <HomeTopSec />
      </div>
      <div className="mobile">
        <MobileView />
      </div>
  
  
  
  
  </>
  
  )
}

export default App
