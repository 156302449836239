const QuestionAnswer = [
  {
    ques: "What are RbTZ?",
    ans: "> RbTZ are a collection of algorithmically generated robot characters based on the original designs by Stevan Lončarević and minted as NFTs on the Ethereum blockchain. A total of 10,000 robots will be minted in 2022. RbTZ have different body types and features. Each RbTZ has a unique set of parts and thus it's own unique reference number used to distinguish them apart.",
  },
  {
    ques: "Who is behind RbTZ?",
    ans: "> The RbTZ team is Mido in dev, Steva in art, and Faerveldir in community, PR, & jack of all trades.",
  },
  {
    ques: "what do I get?",
    ans: "> You get different image assets of your RbTZ, including a PNG, and SVG file (which is resolution free.) Each RbTZ comes with rights to create and distribute derivatives. Read more on license agreement.",
  },
  {
    ques: "How do I get my stuff",
    ans: "> Visit Your Stuff to view all the assets associated with your Roboto, including the SVG (vector graphics), transparent background, RbTZ spec sheet, and more.",
  },
  {
    ques: "Why RbTZ?",
    ans: "> By collecting RbTZ you'll have a voice in the community and help guide the direction of the project and development of the story. We hope to build great ideas together and the grow the RbTZ ecosystem!",
  },
  {
    ques: "What's an nft?",
    ans: `> NFT stands for "Non-fungible token," which means that it's a unique, digital item with blockchain-managed ownership that users can buy, own, and trade. Some NFT's fundamental function is to be digital art. But they can also offer additional benefits like exclusive access to websites, event tickets, game items, and ownership records for physical objects. Think of it as a unique piece of art that can also work as a "members-only" card. RbTZ work like this.`,
  },
//   {
//     ques: "How do I nft?",
//     ans: "> New to NFTs? So are we but no worries, here are some steps on what you need to do to get your RbTZ.  Download the metamask.io extension for the Chrome/Brave browser or app on mobile. This will allow you to make purchases with Ethereum and can be found in the extensions tab. If you are on mobile, you must use the Metamask App Browser. You can purchase Ethereum through the Metamask Wallet using Wyre or Send Ethereum from an exchange like Coinbase. Click on Connect at the top of the page and connect your Metamask. Once joined, you will be able to purchase the NFTs in the mint section. You will be prompted to sign your transaction. FYI, there will be a fee associated with every transaction related to gas prices. Once you have made your purchase, your RbTZ NFT will be viewable in your wallet and on OpenSea.",
//   },
];

export default QuestionAnswer;
