import axios from 'axios'
import React, { useEffect, useState } from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Children } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
// import { stringify } from 'csv-stringify';

function Subscriber() {
  const [email, setemail] = useState([])
  const [show,setshow]=useState(false)
  const [passowrd,setpassword] = useState("")
  const [passtrue,setpasstrue]=useState(false)

  const getdata = async () => {
    try {
      const res = await axios.get("http://13.49.213.13:5000/mail/getsubscriber")
      setemail(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getdata()
  }, [])


  const downloadmails=async()=>{
    try {
      // Make the API request to retrieve the data
      const response = await fetch('http://13.49.213.13:5000/mail/getsubscriber');
      const data = await response.json();

      // Convert the data to CSV format using PapaParse
      const csv = Papa.unparse(data);

      // Create a Blob object from the CSV data
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8' });

      // Save the CSV file using FileSaver.js
      saveAs(csvData, 'data.csv');
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  }

  // const downloadmails = async () => {
  //   console.log("it works")
  //   const data = email;
  //   const doc = new jsPDF();
  //   const pdfWidth = doc.internal.pageSize.getWidth();
  //   const heading = 'Rbtz Subscriber Emails';
  //   const headingWidth = doc.getStringUnitWidth(heading) * doc.internal.getFontSize(); // Calculate the width of the heading text

  //   doc.setFontSize(18);
  //   doc.text(heading, (pdfWidth - headingWidth) / 4 , 15); // Center the heading

  //   doc.setFontSize(12);
  //   let yPos = 30; // Initial y-position for the first email
  //   let counter = 1; // Email counter

  //   data.forEach(item => {
  //     doc.text(`${counter}. ${item.email}`, 10, yPos);
  //     yPos += 10; // Increase the y-position to create space between emails
  //     counter++; // Increment the email counter
  //   });


  //   doc.save('Rbtz Subscriber Emails.pdf');

  // }


  const handlepassword=()=>{
    if(passowrd === "%9erwf5tg"){
      setshow(true)
      localStorage.setItem('password', passowrd)
    }
    else{
      setpasstrue(true)
    }
  }
//   const check=()=>{
//     if(localStorage.getItem("password"){
//       setshow(true)
//     }

//   }
//   useEffect(()=>{
// check()
//   },[])
  return (<>
    <div className='subscribermain_div'>
      <div className='d-flex justify-content-center pt-5'>
        <div className='subscriber_div'>
          <p style={{display:!show ? "block" : "none",lineHeight:"40px"}}>Enter password to see subscribers list</p>
          <p style={{display:show ? "block" : "none"}}>Rbtz Subscribers</p>
        </div>
      </div>
      <div className='d-flex justify-content-center mt-5' >
        <div className='password_card text-center p-5 subscriber_div' style={{display:!show ? "block" : "none"}}>
          <p style={{display:passtrue ? "block" :"none"}}>Password Incorrect</p>
        <input placeholder='Enter password here....' className='password_div' onChange={(e)=>setpassword(e.target.value)} /><br></br>
        <button className='ok_btn' onClick={handlepassword}>Ok</button>

        </div>

      </div>

      <div className='container' style={{display:show ? "block" : "none"}}>
        <div className='d-flex justify-content-end mt-5'>
          <button className='d_m' onClick={downloadmails}>Download all emails</button>
        </div>
        <div className='tablediv d-flex justify-content-center'>
          <div className='mt-5 inner_table' style={{ borderRadius: "10px", width: "100%", maxWidth: "80%", height: "70vh", overflowY: "scroll" }}>
            {
              email?.map((value, index) => {
                return (<>


                  <div className='table_inner gap-5 mt-5'>
                    <div className='table1'>
                      <p>{index + 1}</p>
                    </div>
                    <div className='table2'>
                      <p>{value.email}</p>
                    </div>
                  </div>



                </>)
              })
            }
          </div>

        </div>
      </div>





    </div>




  </>

  )
}

export default Subscriber