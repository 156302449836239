import React, { useState } from "react";
import Arrow_Select from "../../assets/Arrow_Select1.png";
import B_RbtZ_Left from "../../assets/B_RbtZ_Left.png";
import Canisters from "../../assets/Canisters.png";
import RbtzDetail from "../../assets/rbts-detail.png";
import C_Line_bottom from "../../assets/C_Line_bottom.png";
// import Capture from "../../assets/Capture.PNG"
import "./MobileRbtzDetail.css";
import greenbox from "../../assets/B_Green_Box.png";
import wrobot from "../../assets/rbts-detail1.png"
import greenboxs from "../../assets/CompositeLayer2.png";
import robot from "../../assets/robot2.png";
import barcode from "../../assets/B_Undertext.png";
import robo2 from "../../assets/sec2r.png";

function MobileRbtzDetail() {

  const handleDisplay = (e) => {
    console.log("You click me")
    console.log(e.target.id)
    e.target.style.display = "none";
    let elem = document.getElementById("ques_" + e.target.id);
    elem.style.display = "block";
  }

  const [active, setActive] = useState("")

  const handleDisplay2 = (e) => {
    console.log("You click 2")
    console.log(e.target.id)
    e.target.style.display = "none";
    let elem = document.getElementById(e.target.id.split("_")[1]);
    elem.style.display = "block";
  }

  const [show, setShow] = useState(false)

  return (<>
    <div className="MobileRbtzDetailParent">

      <img className="greenbox_img" src={greenbox} />
      <div className="sec2_mobil_text">
        <p className={`${show ? 'd-none' : 'd-flex'}`} onClick={() => setShow(!show)} style={{ cursor: "pointer" }}>
          &gt; Each NFT is algorithmicalg generated by combining 1$0+ traits withvaryingraritg across ranks....
        </p>
        <p className={`bbbb ${show ? 'd-flex' : 'd-none'}`} onClick={() => setShow(!show)} style={{ cursor: "pointer" }} >
          &gt; Each NFT is algorithmicalg generated by combining 1$0+ traits with
          varyingraritg across ranks. Each NFT has a unique combination of traits
          specified by a reference number.
        </p>
        <div className="border_div"></div>

      </div>
      {/* <div className="MobileRbtzDetailChild1">
        &gt; Each NFT is algorithmicalg generated by combining 1$0+ traits with
        varyingraritg across ranks. Each NFT has a unique combination of traits
        specified by a reference number.
        <img className="C_Line_bottom" src={C_Line_bottom} alt="" />
      </div> */}

      <div className="MobileRbtzDetailChild2 d-flex justify-content-center align-items-end">
        <div className="MobileRbtzDetailChild2Left ">
          <div className="inner_div">
            <div className="types">
              {" "}
              <img src={Arrow_Select} alt="" /> <span>Types &gt;</span>{" "}
            </div>
            <div className="overall">
              <span>Overall : </span> <span>1024</span>{" "}
            </div>
            <div className={`rbtz-l-detail ${active === "Squire" ? "rbtz-l-detail-active" : ""}`} onClick={() => setActive("Squire")}>
              {" "}
              <span>
                {" "}
                <svg width="13" height="14" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="21" height="59" fill="#676767" />
                  <rect x="80.4019" y="58.1888" width="21" height="59" transform="rotate(90 80.4019 58.1888)" fill="#676767" />
                  <rect x="80" width="39" height="40" transform="rotate(90 80 0)" fill="#676767" />
                </svg> Squire{" "}
              </span>{" "}
              <span>768</span>{" "}
            </div>
            <div className={`rbtz-l-detail ${active === "Knight" ? "rbtz-l-detail-active" : ""}`} onClick={() => setActive("Knight")}>
              {" "}
              <span>
                {" "}
                <svg width="13" height="14" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 0V20H39.5V40H59V60H79V0H20Z" fill="#676767" />
                  <rect y="20" width="20" height="40" fill="#676767" />
                  <rect x="20" y="60" width="39" height="19" fill="#676767" />
                </svg> Knight{" "}
              </span>{" "}
              <span>205</span>{" "}
            </div>
            <div className={`rbtz-l-detail ${active === "Elite" ? "rbtz-l-detail-active" : ""}`} onClick={() => setActive("Elite")}>
              {" "}
              <span>
                {" "}
                <svg width="13" height="14" viewBox="0 0 79 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 59H0V98.5H58.5V78.5H20V59Z" fill="#676767" />
                  <rect x="19" width="20" height="40" fill="#676767" />
                  <rect x="39" y="40" width="20" height="19" fill="#676767" />
                  <rect x="59" y="59" width="20" height="20" fill="#676767" />
                </svg> Elite{" "}
              </span>{" "}
              <span>41</span>{" "}
            </div>
            <div className={`rbtz-l-detail ${active === "Rare" ? "rbtz-l-detail-active" : ""}`} onClick={() => setActive("Rare")}>
              {" "}
              <span>
                {" "}
                <svg width="13" height="14" viewBox="0 0 79 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="21" height="78" fill="#676767" />
                  <rect x="79" y="59" width="21" height="79" transform="rotate(90 79 59)" fill="#676767" />
                  <rect x="39" y="19" width="20" height="18" transform="rotate(90 39 19)" fill="#676767" />
                  <rect x="59" y="39" width="20" height="20" transform="rotate(90 59 39)" fill="#676767" />
                  <rect x="79" width="20" height="20" transform="rotate(90 79 0)" fill="#676767" />
                </svg> Rare{" "}
              </span>{" "}
              <span>10</span>{" "}
            </div>
          </div>
          <div className="MobileRbtzDetailChild2LeftImg">
            <img
              className="MobileRbtzDetailChild2LeftImg1"
              src={B_RbtZ_Left}
              alt="logos"
            />
            <img
              className="MobileRbtzDetailChild2LeftImg2"
              src={Canisters}
              alt="logos"
            />
          </div>
        </div>
        <div className="robot_bottom2">
          <div className="white_background">
            <img src={wrobot} />
          </div>
          <div className="sec2robo w-100">
           
              <img style={{ width: "100%", maxWidth: "90%" }} src={robo2} />
           
          </div>
          <div className="MobileRbtzDetailChild2Right ">

          </div>
        </div>

      </div>
    </div>

    <div className="robot2_div d-none">
      <div className="MobileRbtzDetailParent1">

        <img className="greenbox_img" src={greenboxs} style={{ zIndex: "9999999999" }} />
        <div className="sec2_mobil_text">
          <p > &gt; Each NFT is algorithmicalg generated by combining 1$0+ traits with
            varyingraritg across ranks. Each NFT has a unique combination of traits
            specified by a reference number.</p>
          <div className="border_div"></div>

        </div>



      </div>
      {/* <img className="robot2_img" src={robot} /> */}
      <div className="robot_bottom">
        <div className="d-flex w-100 gap-3 align-items-center ps-3" >
          <img src={barcode} className="barcode1" />
          <button className="robot_btn" >
            View on OpenSea
          </button>
          <img src={barcode} className="barcode2" />
        </div>
      </div>
    </div>
  </>);
}

export default MobileRbtzDetail;
